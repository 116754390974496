import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaSquarespace, FaWallet } from "react-icons/fa";
import { AiFillHome, AiFillDashboard, AiFillWallet } from "react-icons/ai";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { RiBankFill } from "react-icons/ri";
import { TfiAnnouncement } from "react-icons/tfi";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { CiCreditCard1 } from "react-icons/ci";
import PropTypes from "prop-types";
import { RiExchangeLine } from "react-icons/ri";
import { LiaShareAltSolid } from "react-icons/lia";
import { BiSupport } from "react-icons/bi";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { IoAnalyticsSharp } from "react-icons/io5";
import { TbTargetArrow } from "react-icons/tb";
import { MdNotificationsNone } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { IoInformationCircleOutline } from "react-icons/io5";
import { RiAdminLine } from "react-icons/ri";
import { MdLockPerson } from "react-icons/md";
import { IoEarthOutline } from "react-icons/io5";
import { GoReport } from "react-icons/go";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaCircleDollarToSlot } from "react-icons/fa6";
import { FaSackDollar } from "react-icons/fa6";
 import { MdOutlineAccountBalance } from "react-icons/md";

import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import NavItem from "./NavItem";
import {
  FaClipboardCheck,
  FaMoneyBillWave,
  FaMoneyCheck,
  FaTicketAlt,
} from "react-icons/fa";
import { GrStakeholder } from "react-icons/gr";
import { AiOutlineTransaction } from "react-icons/ai";
import { BiDonateBlood, BiTransfer } from "react-icons/bi";
import { MdDescription } from "react-icons/md";
import { BsFillWalletFill } from "react-icons/bs";
import { RiHome6Line } from "react-icons/ri";
import { HiArrowsUpDown, HiOutlineBell } from "react-icons/hi2";
import { SiApacherocketmq } from "react-icons/si";
import { LiaRocketSolid } from "react-icons/lia";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { IoIosLock } from "react-icons/io";
import { RiSettingsFill } from "react-icons/ri";
import { FaLayerGroup, FaUserCog } from "react-icons/fa";
import { AuthContext } from "src/context/Auth";
import { CiNoWaitingSign } from "react-icons/ci";
import { GrTransaction } from "react-icons/gr";
import { RiHome2Line } from "react-icons/ri";
import { SlSupport } from "react-icons/sl";


function renderNavItems({ items, pathname, depth = 0, state, setSelectedTab }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            state,
            setSelectedTab,
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  state,
  setSelectedTab,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    const isActive =
      item.type == "moneyTransfer" && pathname == "/money-transfer-withdraw";

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={
          Boolean(isActive || open) ||
          item.items.some((child) =>
            matchPath(pathname, { path: child.href, exact: true })
          )
        } // Keep parent item open if any of its children match the current path
        title={item.title}
        // bagde={item.bagde}
        setSelectedTab={(item) => setSelectedTab(item)}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          state,
          // bagde:item.bagde,
          setSelectedTab,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        tabview={item.tabview}
        key={key}
        title={item.title}
        bagde={item.bagde}
        setSelectedTab={(item) => setSelectedTab(item)}
      />
    );
  }
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 320,
    background: "#fff",
    boxShadow: "0px 0px 7px rgb(0 0 0 / 11%)",
  },
  desktopDrawer: {
    top: "78px",
    width: "300px",
    height: "calc(100% - 75px)",
    zIndex: "1",
    // margin: "5px 10px 10px 15px",
    background: "#fff",
    boxShadow: "0px 0px 7px rgb(0 0 0 / 11%)",
    // borderRadius: "20px",
    // marginTop: "35px",
    // marginLeft: "13px"
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      paddingLeft: "44px",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "17px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
    color: "#000",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      paddingLeft: "45px !important",
      width:"100%"
    }
  },
  "& .makeStyles-buttonLeaf-29.depth-0 .makeStyles-title-31": {
    fontSize: "16px",
    fontWeight: 600,
  },
}));

const sections1 = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiFillHome,
        href: "/sniper-dashboard",
        tabview: "Sniper",
      },
      {
        title: "Bot settings",
        icon: AiFillDashboard,
        href: "/bot-setting",
        tabview: "Sniper",
      },
      {
        title: "Transaction History",
        icon: FaSquarespace,
        href: "/sniper-transactions",
        tabview: "Sniper",
      },
    ],
  },
];

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: RiHome2Line,
        href: "/dashboard",
        type: "home",
      },
      // {
      //   title: "Requests",
      //   icon: FaUserCog,
      //   href: "/user-list",
      //   type: "userManagement",
      // },
      {
        title: "Users",
        icon: RiExchangeLine,
        type: "userManagement",
        items: [
          {
            title: "Active Users",
            href: "/active-user",
          },
          // {
          //   title: "Deactivated",
          //   href: "/comingsoon",
          // },
        ],
      },
            {
        title: "Push Notification",
        icon: MdNotificationsNone,
        href: "/push-notification",
        type: "pushNotification",
      },
      // {
      //   title: "Cards",
      //   icon: CiCreditCard1,
      //   type: "card",
      //   href: "/cards",
      // },
    

      {
        title: "Money Transfers",
        icon: FaCircleDollarToSlot,
        href: "/moneyTransfer",
        type: "moneyTransfer",
        items: [
          // {
          //   title: "Deposit & Withdrawal Requests",
          //   href: "/dashboard",
          // },
          {
            title: "Internal Transfers",
            href: "/internal",
          },
          {
            title: "Manual Transfers",
            href: "/money-transfer-deposit",
          },

        
          // {
          //   title: "Local Transactions",
          //   href: "/local-transfer",
          // },
          // {
          //   title: "Report & Analytics",
          //   href: "/reports",
          // },
        ],
      },
      // {
      //   title: "Referral Program",
      //   icon: CiNoWaitingSign,
      //   href: "/waitingList",
      //   type: "waitingList",
      // },
      // {
      //   title: "Supports",
      //   icon: IoEarthOutline,
      //   href: "/international",
      //   type: "international",
      // },
      {
        title: "Subscription & Fees",
        icon: GrTransaction,
        href: "/subscriptionAndFeeMngmt",
        type: "subscriptionAndFeeMngmt",
      },
      
      {
        title: "Accounting",
        icon: IoAnalyticsSharp,
        href: "/accounting",
        type: "accounting",
        items: [
          {
            title: "Liquidity Management",
            href: "/liquidity",
          },
          // {
          //   title: "Edit Liquidity",
          //   href: "/edit",
          // },
          {
            title: "Audit Trails & Logs",
            href: "/trails",
          },
          {
            title: "Revenue & Expense Management",
            href: "/revenue",
          },
        ],
      },
        {
        title: "Savings",
        icon: MdLockPerson,
        href: "/savings",
        type: "savings",
      },
      {
        title: "USDT Request",
        icon: CiNoWaitingSign,
        href: "/usdt",
        type: "USDTRequest",
      },
      {
        title: "All Transaction",
        icon: IoEarthOutline,
        href: "/international",
        type: "international",
      },
      {
        title: "Card Liquidity",
        icon: SlSupport,
        href: "/exchange",
        type: "exchange",
        // bagde:20
      },
      {
        title: " Account Transfer",
        icon: MdOutlineAccountBalance,
        href: "/accountTransfer",
        type: "accountTransfer",
        // bagde:20
      },
      {
        title: "Recharge",
        icon: BiSupport,
        href: "/recharge",
        type: "recharge",
        // bagde:20
      },
          {
        title: "Waiting List",
        icon: CiNoWaitingSign,
        href: "/waitingList",
        type: "waitingList",
      },
         {
        title: "Support",
        icon: BiSupport,
        href: "/supportChat",
        type: "support",
        bagde: 20,
      },
            {
        title: "Sales & Marketing",
        icon: TbTargetArrow,
        href: "/sales-and-marketing",
        type: "salesAndMarketing",
      },
          {
        title: "Loans",
        icon: BiDonateBlood,
        href: "/loans",
        type: "loans",
      },
            {
        title: "Admin Management",
        icon: RiAdminLine,
        href: "/admin-management-new",
        type: "subAdminManagement",
      },
            {
        title: "Change Password",
        icon: RiLockPasswordFill,
        href: "/change-password",
        type: "changePassword",
      },
      // {
      //   title: "Change password",
      //   icon: TbTargetArrow,
      //   href: "/sales-and-marketing",
      //   type: "salesAndMarketing",
      // },

      // {
      //   title: "Loans",
      //   icon: BiDonateBlood,
      //   href: "/loans",
      //   type: "loans",
      // },
      // {
      //   title: "Report Management",
      //   icon: GoReport,
      //   href: "/report-management",
      //   type: "report",
      // },
      // {
      //   title: "FAQ Management",
      //   icon: MdDescription,
      //   href: "/faq-list",
      // },

      // // {
      // //   title: "Static Information",
      // //   icon: IoInformationCircleOutline,
      // //   href: "/static-list",
      // //   type:'staticInformation',
      // // },

      // {
      //   title: "Admin Management",
      //   icon: RiAdminLine,
      //   href: "/admin-management-new",
      //   type: "subAdminManagement",
      // },

    ],
  },
];

const NavBar = ({ onMobileClose, openMobile, tabView, setSelectedTab }) => {
  const classes = useStyles();
  const location = useLocation();

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const renderedSections = tabView === "Arbitrage" ? sections : sections1;
  const data = useContext(AuthContext);
  const permissions = data?.userData?.permissions;
  // const filteredSections = sections.map(section => ({
  //   items: section.items.filter(item => {
  //     const itemType = item.type;
  //     if (permissions[itemType] !== false) {
  //       return permissions[itemType];
  //     }
  //     return false; // Return true for items not found in the permission object
  //   }),
  // }));

  const filteredSections = useMemo(() => {
    if (!permissions || data?.userData?.userType === "ADMIN") return sections; // If permissions are not defined or the user is an admin, return the original sections

    return sections.map((section) => ({
      items: section.items.filter((item) => {
        const itemType = item.type;
        if (itemType === "changePassword" || itemType === "recharge" ) {
          // Always show the "Change Password" and "Recharge" sections
          return true;
        }
        // Filter other items based on permissions
        if (permissions[itemType] !== false) {
          return permissions[itemType];
        }
        return false;
      }),
    }));
  }, [permissions, data]);

  // const filteredSections = useMemo(() => {
  //   if (!permissions || data?.userData?.userType === "ADMIN") return sections; // If permissions are not defined, return the original sections

  //   return sections.map((section) => ({
  //     items: section.items.filter((item) => {
  //       const itemType = item.type;
  //       if (permissions[itemType] !== false) {
  //         return permissions[itemType];
  //       }
  //       return false;
  //     }),
  //   }));
  // }, [permissions, data]);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box className="sideMenuBox">
            {filteredSections?.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section.img,
                  items: section.items,
                  pathname: location.pathname,
                  state: section.tabView,
                  setSelectedTab,
                })}
              </List>
            ))}
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography variant="h6" color="primary">
                  Are you sure want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2}>
                <Button
                  className="modelbtn"
                  color="primary"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    sessionStorage.removeItem("adminToken");
                    history.push("/");
                    window.location.reload();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
