import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import AuthGuard from "./component/AuthGuard";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },

  {
    exact: true,
    path: "/kyc-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/KycList")
    ),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MoneyTransfer/DepositeWidtrawal")
    ),
  },
  // {
  //   exact: true,
  //   path: "/dashboard",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
  //   ),
  // },
  {
    exact: true,
    path: "/testing-page",
    guard: true,
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TestingPage/Demo1")
    ),
  },
  {
    exact: true,
    path: "/hot-wallet",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HotWalletManagemnet/HotWallet")
    ),
  },
  {
    exact: true,
    path: "/commission-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HotWalletManagemnet/CommissionDetail")
    ),
  },
  {
    exact: true,
    path: "/report-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReportManagement/Report")
    ),
  },
  {
    exact: true,
    path: "/bank-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/Bank")
    ),
  },
  {
    exact: true,
    path: "/deposite-withdrawal",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MoneyTransfer/DepositeWidtrawal")
    ),
  },
  {
    exact: true,
    path: "/exchange",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CardLiquidity/Exchange")
    ),
  },
  {
    exact: true,
    path: "/accountTransfer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AccountTransfer/Account")
    ),
  },
  {
    exact: true,
    path: "/internal",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MoneyTransfer/InternalTransfer")
    ),
  },
  {
    exact: true,
    path: "/local-transfer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MoneyTransfer/LocalTransfer")
    ),
  },
  {
    exact: true,
    path: "/money-transfer-deposit",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MoneyTransfer/MoneyTransferDeposit")
    ),
  },
  {
    exact: true,
    path: "/money-transfer-withdraw",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MoneyTransfer/MoneyTransferWithdraw")
    ),
  },
  {
    exact: true,
    path: "/finance",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AnalyticsAndFinance/Finance")
    ),
  },
  {
    exact: true,
    path: "/crypto",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CryptoFees/Crypto")
    ),
  },
  {
    exact: true,
    path: "/active-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserListing/AvtiveUser")
    ),
  },
  {
    exact: true,
    path: "/comingsoon",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/InvestingTransaction/ComingSoon")
    ),
  },
  {
    exact: true,
    path: "/urgent-transaction-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/InvestingTransaction/UrgentTransactionRequest")
    ),
  },
  {
    exact: true,
    path: "/reports",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Accounting/ReportAnalytics")
    ),
  },
  {
    exact: true,
    path: "/internal-transfer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MoneyTransfer/InternalTransfer")
    ),
  },
  {
    exact: true,
    path: "/cards",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Cards/Cards")),
  },
  {
    exact: true,
    path: "/savings",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Savings/Savings")),
  },
  {
    exact: true,
    path: "/usdt",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/USDT/UsdtRequest")),
  },
  {
    exact: true,
    path: "/waitingList",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/WaitingList/Waiting")),
  },
  {
    exact: true,
    path: "/international",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InterantionRequest/InternationalRequest")),
  },
  {
    exact: true,
    path: "/subscription-fee-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/SubscriptionManagement/SubscriptionManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/bank-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/BankList")
    ),
  },
  {
    exact: true,
    path: "/add-bank",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/AddBank")
    ),
  },
  {
    exact: true,
    path: "/all-transaction",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TransactionManage/TransactionTabs")
    ),
  },
  {
    exact: true,
    path: "/recharge",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Recharge/Recharge")
    ),
  },
  {
    exact: true,
    path: "/wallet-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WalletManagement/TransactionHistory")
    ),
  },
  {
    exact: true,
    path: "/all-transactions",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AllTransaction/Transaction")
    ),
  },
  {
    exact: true,
    path: "/add-coin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WalletManagement/AddCustomCoin")
    ),
  },
  {
    exact: true,
    path: "/View-wallet-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WalletManagement/ViewWalletHistory")
    ),
  },

  {
    exact: true,
    path: "/View-account-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AccountTransfer/ViewAccount")
    ),
  },

  {
    exact: true,
    path: "/gift-transfer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardManagement/GiftManagement")
    ),
  },
  {
    exact: true,
    path: "/withdraw-fund",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardManagement/WithdrawFundManage")
    ),
  },
  {
    exact: true,
    path: "/fee-manage",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FeeManagement/FeeManage")
    ),
  },
  {
    exact: true,
    path: "/fund-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FundManagement/FundDetails")
    ),
  },

  {
    exact: true,
    path: "/view-fund",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FundManagement/ViewFundDetails")
    ),
  },
  {
    exact: true,
    path: "/view-deposit",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FundManagement/ViewDeposit")
    ),
  },
  {
    exact: true,
    path: "/view-transfer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FundManagement/ViewTransfer")
    ),
  },

  {
    exact: true,
    path: "/view-kyc",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/ViewKyc")
    ),
  },
  {
    exact: true,
    path: "/announcement-list",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Announcement/ListAnnouncement")
    ),
  },
  {
    exact: true,
    path: "/add-announce",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Announcement/AddAnnouncement")
    ),
  },
  {
    exact: true,
    path: "/ticket-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TicketManagement/TicketList")
    ),
  },
  {
    exact: true,
    path: "/view-ticket",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TicketManagement/ViewTicket")
    ),
  },
  {
    exact: true,
    path: "/stake-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StakingManagement/StakingList")
    ),
  },
  {
    exact: true,
    path: "/tranches-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StakingManagement/TranchesList")
    ),
  },
  {
    exact: true,
    path: "/edit-tranches",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StakingManagement/AddTranches")
    ),
  },
  {
    exact: true,
    path: "/view-stake",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StakingManagement/ViewStake")
    ),
  },

  {
    exact: true,
    path: "/transfer-manage",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TransferManagemnet/TransferManage")
    ),
  },
  {
    exact: true,
    path: "/view-banktransfer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TransferManagemnet/ViewBankTransfer")
    ),
  },
  {
    exact: true,
    path: "/subscriptionAndFeeMngmt",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionFee/NonSubscriber")
    ),
  },
  {
    exact: true,
    path: "/user-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserList")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/StaticData")
    ),
  },
  {
    exact: true,
    path: "/edit-static",

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/EditStaticData")
    ),
  },
  {
    exact: true,
    path: "/sales-and-marketing",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SalesAndMarketing/SalesAndMarketing")
    ),
  },
  {
    exact: true,
    path: "/push-notification",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PushNotification/PushNotification")
    ),
  },
  {
    exact: true,
    path: "/loans",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/LoanManagement/LoanManagement")
    ),
  },
  // {
  //   exact: true,
  //   path: "/admin-management",
  //   layout: DashboardLayout,
  //   guard: true,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/AdminManagement/AdminMAnagement")
  //   ),
  // },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    guard: true,
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },

  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/OtpVerification")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/faq-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/UpdateFaq")
    ),
  },
  {
    exact: true,
    path: "/faq-add",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/AddFaq")
    ),
  },
  {
    exact: true,
    layout: DashboardLayout,
    guard: true,
    path: "/faq-list",
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/Faq")
    ),
  },

  {
    exact: true,
    layout: DashboardLayout,
    guard: true,
    path: "/editfaq-list",
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/EditFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    layout: DashboardLayout,
    path: "/view-faqdata",
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/ViewFaq")
    ),
  },

  {
    exact: true,
    path: "/liquidity",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Accounting/Liquidity")
    ),
  },
  {
    exact: true,
    path: "/edit",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Accounting/EditLiquidity")
    ),
  },
  {
    exact: true,
    path: "/trails",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Accounting/AuditTrailsLog")
    ),
  },
  {
    exact: true,
    path: "/revenue",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Accounting/RevenueExpense")
    ),
  },
  {
    exact: true,
    path: "/faq",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Faq/Faq")),
  },

  // -----******Deepanshu Component Referal routing***

  {
    exact: true,
    path: "/referalProgram",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReferalProgram/Referal")
    ),
  },
  // -----******Deepanshu Component Referal routing***

  {
    exact: true,
    path: "/support",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Support/Support")),
  },

  {
    exact: true,
    path: "/static-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticInformationNew/StaticInformationNew"
      )
    ),
  },

  // {
  //   exact: true,
  //   path: "/super-Admin",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/AdminManagementNew/SuperAdminManagement")
  //   ),
  // },

  {
    exact: true,
    path: "/admin-management-new",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AdminManagementNew/AdminManagementNew")
    ),
  },
  {
    exact: true,
    path: "/supportChat",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Support/ChatMain")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
